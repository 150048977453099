import "./styles.scss"
import React from "react"

const Header = () => {
  return (
    <header>
      <nav className="container">
        <div className="logo">logo</div>
        <div className="menu">menu</div>
      </nav>
    </header>
  )
}

export default Header
